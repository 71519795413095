"use client";

import { useEffect, useRef } from "react";

const AUTO_REFRESH_THRESHOLD = 5 * 60 * 1000; // minutes

const AutoRefreshOnReturn = () => {
  const hiddenTimeRef = useRef<number | null>(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        hiddenTimeRef.current = Date.now();
      } else {
        if (hiddenTimeRef.current) {
          const elapsed = Date.now() - hiddenTimeRef.current;
          if (elapsed >= AUTO_REFRESH_THRESHOLD) {
            window.location.reload();
          }
          hiddenTimeRef.current = null;
        }
      }
    };

    const handleFocus = () => {
      if (hiddenTimeRef.current) {
        const elapsed = Date.now() - hiddenTimeRef.current;
        if (elapsed >= AUTO_REFRESH_THRESHOLD) {
          window.location.reload();
        }
        hiddenTimeRef.current = null;
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleFocus);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  return null;
};

export default AutoRefreshOnReturn;
