import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.26.1_next@15.1.0_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-d_hvzjqp5r5vp64rgz7ueab5mxla/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.0_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./../fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.0_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./../fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/.pnpm/nextjs-toploader@3.7.15_next@15.1.0_babel-plugin-react-compiler@19.0.0-beta-37ed2a7-20241206__rafsp7td7umdtrthvq7ajvo3i4/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/auto-refresh-on-return.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/src/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/providers/react-query.tsx");
